import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
//import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import Seo from "../components/seo";

import parse from 'html-react-parser';

const Services = () => {
  const data = useStaticQuery(graphql`
    query ServicesPage {
      allWpPage(filter: {uri: {eq: "/services/"}}) {
        nodes {
          title
          servicesPage {
            basicServiceDescription
            gatsbyjsServiceDescription
            servicesPageDescription
            wordpressAndGatsbyDescription
            wordpressDescription
          }
        }
      }
    }
  `);

  let servicesPageData = data.allWpPage.nodes[0];
  let pageTitle = servicesPageData.title;
  let servicesPageDescription = servicesPageData.servicesPage.servicesPageDescription;
  let basicServiceDescription = servicesPageData.servicesPage.basicServiceDescription;
  let gatsbyjsServiceDescription = servicesPageData.servicesPage.gatsbyjsServiceDescription;
  let wordpressDescription = servicesPageData.servicesPage.wordpressDescription;
  let wordpressAndGatsbyDescription = servicesPageData.servicesPage.wordpressAndGatsbyDescription;


  return (
    <Layout>
      <Seo title="Services"/>
      <section className="container services-page page-default">
        <div className="text-center text-lg-start">
          <h1 className="display-2 pt-5">{  pageTitle }</h1>
        </div>
        <div className="services-page-description">
          { parse(servicesPageDescription) }
        </div>
        <h2 className="display-4 display-lg-3 text-center text-lg-start pt-5">Basic (Wix, GoDaddy, etc)</h2>
        <div className="basic-service-content">
          { parse(basicServiceDescription) }
        </div>
        <h2 className="display-4 display-lg-3 text-center text-lg-start">Gatsby.js</h2>
        <div className="gatsby-service-content">
          { parse(gatsbyjsServiceDescription) }
        </div>
        <h2 className="display-4 display-lg-3 text-center text-lg-start">WordPress</h2>
        <div className="wordpress-service-content">
          { parse(wordpressDescription) }         
        </div>
        <h2 className="display-4 display-lg-3 text-center text-lg-start">WordPress + Gatsby.js</h2>
        <div className="wordpress-gatsby-service-content">
          { parse(wordpressAndGatsbyDescription) }    
          <p className="fs-5 mt-5">If you have not checked out our prices, 
            please click <Link to="/technologies#gatsby-description">here</Link>.</p>
        </div>
      </section>
    </Layout>
  );
}

export default Services;  